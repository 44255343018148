import React from 'react';
import { componentsMenu, dashboardMenu, demoPages } from '../menu';
import DashboardHeader from '../pages/common/Headers/DashboardHeader';
import DashboardBookingHeader from '../pages/common/Headers/DashboardBookingHeader';
import SummaryHeader from '../pages/common/Headers/SummaryHeader';
import ComponentsHeader from '../pages/common/Headers/ComponentsHeader';
import FormHeader from '../pages/common/Headers/FormHeader';
import ChartsHeader from '../pages/common/Headers/ChartsHeader';
import ContentHeader from '../pages/common/Headers/ContentHeader';
import UtilitiesHeader from '../pages/common/Headers/UtilitiesHeader';
import IconHeader from '../pages/common/Headers/IconHeader';
import ExtrasHeader from '../pages/common/Headers/ExtrasHeader';
import DefaultHeader from '../pages/common/Headers/DefaultHeader';

const headers = [
	{ path: demoPages.login.path, element: null, exact: true },
	{ path: demoPages.signUp.path, element: null, exact: true },
	{ element: <DashboardHeader />, exact: true },

	{
		path: demoPages.forgot.path,
		element: null,
		exact: true,
	},
	{
		path: demoPages.changepw.path,
		element: null,
		exact: true,
	},

	{
		path: dashboardMenu.summary.path,
		element: <SummaryHeader />,
		exact: true,
	},
	{
		path: dashboardMenu.dashboardBooking.path,
		element: <DashboardBookingHeader />,
		exact: true,
	},
	{
		path: `${componentsMenu.components.path}/*`,
		element: <ComponentsHeader />,
	},
	{
		path: `${componentsMenu.forms.path}/*`,
		element: <FormHeader />,
	},
	{
		path: `${componentsMenu.charts.path}/*`,
		element: <ChartsHeader />,
	},
	{
		path: `${componentsMenu.content.path}/*`,
		element: <ContentHeader />,
	},
	{
		path: `${componentsMenu.utilities.path}/*`,
		element: <UtilitiesHeader />,
	},
	{
		path: `${componentsMenu.icons.path}/*`,
		element: <IconHeader />,
	},
	{
		path: `${componentsMenu.extra.path}/*`,
		element: <ExtrasHeader />,
	},
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
