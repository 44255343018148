import React, { useContext } from 'react';
import classNames from 'classnames';
import Header, { HeaderLeft} from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';
import useDarkMode from '../../../hooks/useDarkMode';
import { userAuth } from '../../../contexts/userContext';

const DashboardBookingHeader = () => {
	const { darkModeStatus } = useDarkMode();
	const {  token } = useContext(userAuth);
	return (
		<Header>
			<HeaderLeft>
				<div className='d-flex align-items-center'>
					<div className='row g-4'>
						<div className='col-md-auto'>
							{/* <div
								className={classNames('fs-3', 'fw-bold', {
									'text-dark': !darkModeStatus,
								})}>
								Hi, {token.usrname}!
							</div> */}
						</div>
					</div>
				</div>
			</HeaderLeft>
			<CommonHeaderRight/>				
		</Header>
	);
};

export default DashboardBookingHeader;
