import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';
import ThemeContext from '../contexts/themeContext';
import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { demoPages } from '../menu';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import { userAuth } from '../contexts/userContext';
import { setIn } from 'formik';
import Loader from '../pages/common/vhelper/Loader';
import ErrorBoundary from '../pages/presentation/demo-pages/helper/ErrorBoundaries';

const App = () => {
	getOS();

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	const { token, setToken } = useContext(userAuth);
	const navigate = useNavigate();
	const location = useLocation();
	const [loaderv, setLoaderv] = useState(false);

	// useEffect(() => {
	// 	let authcheck;
	// 	let authcheck2;

	// 	if (token.email) {
	// 		setLoaderv(false);
	// 		authcheck = setInterval(() => {
	// 			//console.log('Interval', token.session_key);
	// 			(async () => {
	// 				const web_link = process.env.REACT_APP_WEB_LINK;
	// 				//console.log('user token', token.session_key);
	// 				const data = { action: 'checkcuslog', email: token.email };
	// 				const res = await fetch(web_link, {
	// 					method: 'post',
	// 					headers: {
	// 						'Content-Type': 'application/json',
	// 						Accept: 'application/json',
	// 						'sdk-authorization': `${token.session_key}`,
	// 					},
	// 					body: JSON.stringify(data),
	// 				});
	// 				const responce = await res.json();
	// 				if (responce.type == 'error') {
	// 					setToken({});
	// 					localStorage.removeItem('user-auth');
	// 					navigate('/');
	// 				}
	// 				//console.log('r login', responce);
	// 			})();
	// 		}, 1000 * 60);
	// 	} else {
	// 		setLoaderv(true);
	// 		authcheck2 = setTimeout(() => {
	// 			//console.log('Timeout', token.session_key);
	// 			(async () => {
	// 				const web_link = process.env.REACT_APP_WEB_LINK;
	// 				//console.log('user token', token.session_key);
	// 				const data = { action: 'checkcuslog', email: token.email };
	// 				const res = await fetch(web_link, {
	// 					method: 'post',
	// 					headers: {
	// 						'Content-Type': 'application/json',
	// 						Accept: 'application/json',
	// 						'sdk-authorization': `${token.session_key}`,
	// 					},
	// 					body: JSON.stringify(data),
	// 				}).catch(() => {
	// 					setToken({});
	// 					setLoaderv(false);
	// 					localStorage.removeItem('user-auth');
	// 					if(location.pathname === '/landing/forgot' || location.pathname === '/landing/changepw'){
	// 						console.log("A")
	// 					}else{
	// 						 navigate('/');
	// 						console.log(" Else A")
	// 					}
	// 					// navigate('/');
	// 				});

	// 				const responce = await res.json();
	// 				console.log('responce', responce);
	// 				if (responce.type == 'error') {
	// 					setToken({});
	// 					setLoaderv(false);
	// 					localStorage.removeItem('user-auth');
	// 					if(location.pathname === '/landing/forgot' || location.pathname === '/landing/changepw'){
	// 						console.log("B")
	// 					}else{
	// 						 navigate('/');
	// 						console.log(" Else B")
	// 					}
	// 					// navigate('/');
	// 				} else {
	// 					setLoaderv(false);
	// 				}
	// 			})();
	// 		}, 300);
	// 	}

	// 	return () => {
	// 		clearInterval(authcheck);
	// 		clearTimeout(authcheck2);
	// 	};
	// }, [token.email,location.pathname]);

	// useEffect(() => {
	// 	let cleartime;
	// 	if (!token.email) {
	// 		cleartime = setTimeout(() => {
	// 			navigate('/');
	// 		}, 300);
	// 	}
	// 	return ()=>{
	// 		clearTimeout(cleartime);
	// 	}
	// }, [token.email, location.pathname]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	
	useEffect(async () => {
		//console.log('Location.pathname', Location.pathname);
		// Check if the URL contains /customer_active and an ID
		//const urlPattern = /customer_active\/([\w=+/]+)/;
		//const match = Location.pathname.match(urlPattern);

		const currentUrl = new URL(window.location.href);
		const searchParams = new URLSearchParams(currentUrl.search);
		// Extracted customer ID
		const customerId = searchParams.get('id');
		//if (match) {
		if (customerId) {
			//const customerId = match[1];
		//	console.log(`URL contains /customer_active with ID: ${customerId}`);

			// Fetch or update customer's information based on the ID
			const item = {
				action: 'customer_active',
				user_id: customerId,
			};
			const web_link = process.env.REACT_APP_WEB_LINK;
			try {
				const res = await fetch(web_link, {
					method: 'post',
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
					},
					body: JSON.stringify(item),
				});
				const result = await res.json();

			//	console.log(`web_link responce`, result);

				if (result.type === 'Success' || result.type === 'success') {
					if (result.data.userrole === 'emp') {
						setToken(result.data);
						localStorage.setItem('user-auth', JSON.stringify(result.data));
						navigate('dashboard');
					} else {
						remove_login();
					}
				} else {
					remove_login();
				}
			} catch (error) {
				console.log(error);
			}
		}
		return () => false;
	}, [Location.pathname]);

	const remove_login = () => {
		setToken({});
		localStorage.removeItem('user-auth');
		navigate('/');
	};

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = [
		demoPages.login.path,
		demoPages.signUp.path,
		demoPages.forgot.path,
		demoPages.changepw.path,
	];
	if (loaderv) {
		return <Loader />;
	}
	return (
		<ErrorBoundary>
			<ThemeProvider theme={theme}>
				<ToastProvider components={{ ToastContainer, Toast }}>
					<TourProvider
						steps={steps}
						styles={styles}
						showNavigation={false}
						showBadge={false}>
						<div
							ref={ref}
							className='app'
							style={{
								backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
								zIndex: fullScreenStatus && 1,
								overflow: fullScreenStatus && 'scroll',
							}}>
							<Routes>
								{withOutAsidePages.map((path) => (
									<Route key={path} path={path} />
								))}
								<Route path='*' element={<Aside />} />
							</Routes>
							<Wrapper />
						</div>
						<Portal id='portal-notification'>
							<ReactNotifications />
						</Portal>
					</TourProvider>
				</ToastProvider>
			</ThemeProvider>
		</ErrorBoundary>
	);
};

export default App;
